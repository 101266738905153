/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2024-01-14 15:09:59
 * @FilePath: \nlt-htxt\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '././assets/styles/border.css'
import '././assets/styles/reset.css'
import SvgIcon from '@/icons'
import '@/router/permission'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import echarts from './util/eharts'
const app = createApp(App)
app.config.globalProperties.$echarts = echarts;

app.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(SvgIcon).mount('#app')
