/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2024-04-23 16:33:37
 * @FilePath: \nlt-htxt\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-07-31 16:37:13
 * @FilePath: \nlt-htxt\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-07-31 15:13:22
 * @FilePath: \nlt-htxt\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-07-31 15:13:07
 * @FilePath: \nlt-htxt\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '首页',
    component: () => import('../views/layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: '/category',
        name: '商品大类',
        component: () => import('../views/category/index.vue'),
      },
      {
        path: '/categories',
        name: '商品小类',
        component: () => import('../views/categories/index.vue'),
      },
      {
        path: '/product',
        name: '所有商品管理',
        component: () => import('../views/product/index.vue'),
      },
      {
        path: '/productSale',
        name: '促销商品管理',
        component: () => import('../views/productSale/index.vue'),
      },
      {
        path: '/user',
        name: '用户管理',
        component: () => import('../views/user/index.vue'),
      },
      {
        path: '/orderIs',
        name: '已购订单管理',
        component: () => import('../views/orderIs/index.vue'),
      },
      {
        path: '/order',
        name: '所有订单管理',
        component: () => import('../views/order/index.vue'),
      },
      {
        path: '/modifyPassword',
        name: '修改密码',
        component: () => import('../views/modifyPassword/index.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
