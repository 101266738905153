/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-08-02 01:13:22
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-12-15 15:18:54
 * @FilePath: \nlt-htxt\src\router\permission.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from "@/router/index";
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext)=>{
    let token:string=window.localStorage.getItem("token");
    // console.log("to.path="+to.path)
    const whiteList: string[] = ['/login']
    if(token){
        if(to.path=="/login"){ // 如果是登录请求
            next("/");
        }else{
            next(); //放行
        }
    }else{
        if(whiteList.includes(to.path)){
            next(); //放行
        }else{
            next("/login") //跳转登录页面
        }
    }
})
    
