/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-28 15:44:50
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-09-29 11:26:54
 * @FilePath: \nlt-htxt\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore, Store } from 'vuex'
import router from '@/router'

interface StateType {
  activeTab: string;
}

const store: Store<StateType> = createStore<StateType>({
  state: {
    activeTab: '',
  },
  getters: {
  },
  mutations: {
    setActiveTab(state, newActiveTab:string) {
      state.activeTab = newActiveTab;
    }
  },
  actions: {
    // 安全退出
    logout() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      store.commit('setActiveTab', '');
      router.replace('/login')
    }
  },
  modules: {
  }
})

export default store;
