/*
 * @Author: Jie你的账 2805889238@qq.com你邮箱地
 * @Date: 2023-07-29 13:58:51
 * @LastEditors: Jie你的账 2805889238@qq.com你邮箱地
 * @LastEditTime: 2023-08-02 01:55:13
 * @FilePath: \nlt-htxt\src\icons\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import SvgIcon from '@/components/SvgIcon/index.vue'

const svgRequired = require.context('./svg', false, /\.svg$/)
svgRequired.keys().forEach((item) => svgRequired(item))

export default (app) => {
  app.component('svg-icon', SvgIcon)
}
